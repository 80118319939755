const initialState = {
    displayBloodGlucose: false,
    displayWeight: false,
    displayHtn: false,
    interval: 90,
    noWeightData: true,
    sections: {
        bg: false,
        weight: false,
        htn: false,
    },
};

export default function logBookControls(
    state = {...initialState},
    {type, payload}
) {
    switch (type) {
        case 'SET_SECTION': {
            const stateCopy = {...state};

            stateCopy.sections[payload.selected] = payload.value;

            return {
                ...stateCopy,
            };
        }

        case 'SECTION_TOGGLE': {
            const stateCopy = {...state};

            stateCopy.sections[payload] = !stateCopy.sections[payload];

            return {
                ...stateCopy,
            };
        }

        case 'DISPLAY_BG': {
            return {
                ...state,
                displayBloodGlucose: payload,
            };
        }

        case 'DISPLAY_WEIGHT': {
            return {
                ...state,
                displayWeight: payload,
            };
        }

        case 'GLP1': {
            return {
                ...state,
                displayWeight: payload,
            };
        }

        case 'NO_WEIGHT_DATA': {
            return {
                ...state,
                noWeightData: payload,
            };
        }

        case 'INTERVALS': {
            return {
                ...state,
                interval: parseInt(payload, 10),
            };
        }

        case 'DISPLAY_HTN': {
            return {
                ...state,
                displayHtn: payload,
            };
        }

        case 'NO_HTN_DATA': {
            return {
                ...state,
                noHtnData: payload,
            };
        }

        default:
            return state;
    }
}
