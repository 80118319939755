import {useState, useEffect} from 'react';
import css from './Logbook.scss';
import cn from 'classnames';
import {mealCodes} from './BgConstants';
import {bgFilterOptions} from './BGFilterOptions';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import setHours from 'date-fns/setHours';
import getHours from 'date-fns/getHours';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInDays from 'date-fns/differenceInDays';
import Arg from '@livongo/arg';
import {BGsResource} from './BGsResource';
import Weight from './weight/Weight';
import Htn from './htn/Htn';
import {useSelector, useDispatch} from 'react-redux';
import AdminUserResource from '../resources/admin_user';
import {UserResource} from './res_user';
import BGHeader from './BGHeader';
import SummaryView from './SummaryView';
import ListView from './ListView';
import TagView from './TagView';
import TimeView from './TimeView';
import {getWeightInfo} from './weight/WeightInfo';
import {getHtnInfo} from './htn/HtnInfo';
import LabResults from './labResults/LabResults';
import useTransLoader from 'i18n/use-trans-loader';
import {useRouteMatch} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import filterArray from 'lodash/filter';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import sumBy from 'lodash/sumBy';
import round from 'lodash/round';
import groupBy from 'lodash/groupBy';
import each from 'lodash/each';
import floor from 'lodash/floor';

const Logbook = props => {
    const {path} = useRouteMatch();
    const {t} = useTransLoader(path);
    const filter = useSelector(state => state.logBookFilters);
    const controls = useSelector(state => state.logBookControls);
    const [bgStatistics, setBgStatistics] = useState('');
    const dispatch = useDispatch();
    const timeIntervals = [];
    let isGLP1 = false;
    let isGLP1HTN = false;

    for (let i = 0; i < 24; i += 3) {
        timeIntervals.push({
            start: setHours(new Date(), i),
            end: setHours(new Date(), i + 3),
            dataKey: `${i}To${i + 3}`,
        });
    }
    const [allBgs, setAllBgs] = useState([]);
    const [userToken, setUserToken] = useState('');
    const [userClient, setUserClient] = useState('');

    // eslint-disable-next-line no-shadow
    const filterResponse = allBgs => {
        // first apply day of the week filter if it is set
        if (!isEmpty(filter.weekDay)) {
            // eslint-disable-next-line no-param-reassign
            allBgs =
                filterArray(allBgs, bg => {
                    return get(
                        Object.values(filter.weekDay),
                        getDay(new Date(get(bg, 'timestamp')))
                    );
                }) || [];
        }

        // apply meal tag filter
        if (!isEmpty(get(filter, 'mealCode'))) {
            const mealCodeFilter = get(filter, 'mealCode.value');

            // eslint-disable-next-line no-param-reassign
            allBgs =
                filterArray(allBgs, bg => {
                    return (
                        mealCodeFilter === get(bg, 'tags.eat_code') ||
                        (mealCodeFilter === -1 &&
                            isUndefined(get(bg, 'tags.eat_code')))
                    );
                }) || [];
        }

        // apply BG range filter
        if (!isEmpty(get(filter, 'bgValue'))) {
            if (isUndefined(get(filter.bgValue, 'operation'))) {
                // the filter options are from the PDF
                // eslint-disable-next-line no-undef
                setFilter(prevState => {
                    return {
                        ...prevState,
                        bgValue: find(bgFilterOptions, value => {
                            return value.label === bgValue.label; // eslint-disable-line no-undef
                        }),
                    };
                });
            }
            const bgRangeFunc = get(filter.bgValue, 'operation');

            // eslint-disable-next-line no-param-reassign
            allBgs =
                filterArray(allBgs, bg => {
                    return bgRangeFunc(get(bg, 'value'));
                }) || [];
        }

        // apply BG source filter
        if (!isEmpty(get(filter, 'source'))) {
            const sourceValues = Array.isArray(filter.source.value)
                ? filter.source.value
                : [];

            // eslint-disable-next-line no-param-reassign
            allBgs =
                filterArray(allBgs, bg => {
                    return sourceValues.indexOf(get(bg, 'tags.source')) !== -1;
                }) || [];
        }
        setAllBgs(allBgs);
        dispatch({
            type: 'LIST_VIEW',
            payload: allBgs,
        });
        dispatch({
            type: 'SUMMARY_VIEW',
            payload: allBgs,
        });
    };

    const getBgs = token => {
        const conditions = (Arg('conditions') || '').split(';');

        if (indexOf(conditions, 'Diabetes') !== -1) {
            dispatch({
                type: 'DISPLAY_BG',
                payload: true,
            });
            dispatch({
                type: 'SET_SECTION',
                payload: {
                    selected: 'bg',
                    value: true,
                },
            });

            const startDate = subDays(
                startOfDay(new Date()),
                parseInt(filter.days, 10)
            );

            const parameters = {
                start: format(startDate, "yyyy-MM-dd'T00:00:00'"),
                tagsIsControl: false,
            };

            // check if we should include OOR bgs
            if (!get(filter, 'inludeOOR')) {
                parameters.tagsOOR = false;
            }

            // // check if we should add manual data
            if (!get(filter, 'includeManual')) {
                parameters.tagsSource = '1,11';
            }
            if (token) {
                BGsResource.query(parameters, token).then(data => {
                    data.bgs.sort(
                        (a, b) =>
                            Date.parse(b.timestamp) - Date.parse(a.timestamp)
                    );
                    filterResponse(get(data, 'bgs'));
                });
            }
        }
    };

    const getBgSummary = token => {
        const conditions = (Arg('conditions') || '').split(';');

        if (indexOf(conditions, 'Diabetes') !== -1) {
            const start30Days = subDays(
                startOfDay(new Date()),
                parseInt(30, 10)
            );

            const start90Days = subDays(
                startOfDay(new Date()),
                parseInt(90, 10)
            );

            const parameters30Days = {
                start: format(start30Days, "yyyy-MM-dd'T00:00:00'"),
                tagsIsControl: false,
            };

            const parameters90Days = {
                start: format(start90Days, "yyyy-MM-dd'T00:00:00'"),
                tagsIsControl: false,
            };

            if (token) {
                const summaryStatistics = {};

                BGsResource.getBgStatistics(parameters30Days, token).then(
                    data => {
                        summaryStatistics.sum30Days = data;
                        setBgStatistics(summaryStatistics);
                    }
                );

                BGsResource.getBgStatistics(parameters90Days, token).then(
                    data => {
                        summaryStatistics.sum90Days = data;
                        setBgStatistics(summaryStatistics);
                    }
                );
            }
        }
    };

    const handleCheckChange = selected => {
        dispatch({
            type: 'SECTION_TOGGLE',
            payload: selected,
        });
    };

    const getUserToken = () => {
        const pid = Arg('pid');

        if (!isUndefined(pid)) {
            AdminUserResource.getUserToken(pid)
                .then(response => {
                    const token = get(response, 'data.access_token');

                    UserResource.getAccountInfo(token)
                        .then(data => {
                            const clientCode = data.groups.organization.code;

                            setUserClient(clientCode);
                        })
                        .catch(err => console.log(err)); // eslint-disable-line no-console

                    UserResource.getUserPrograms(token)
                        .then(data => {
                            const programs = data || [];
                            const gRP1Programs = [
                                'ADV_WEIGHT_MANAGEMENT',
                                'ADV_PREDIABETES',
                                'COMPR_WEIGHT_CARE',
                                'COMPR_PREDIABETES_CARE',
                            ];

                            programs.forEach(program => {
                                const pg = program.program.toUpperCase();
                                const isActive = program.v2Status === 'ACTIVE';

                                if (gRP1Programs.includes(pg) && isActive)
                                    isGLP1 = true;
                                if (
                                    (pg === 'COMPR_WEIGHT_CARE' ||
                                        pg === 'COMPR_PREDIABETES_CARE') &&
                                    isActive
                                )
                                    isGLP1HTN = true;
                            });

                            getWeightInfo(
                                controls.interval,
                                dispatch,
                                token,
                                isGLP1
                            );
                            getBgs(token);
                            getBgSummary(token);
                            getHtnInfo(
                                controls.interval,
                                dispatch,
                                token,
                                isGLP1HTN
                            );
                        })
                        .catch(err => console.log(err)); // eslint-disable-line no-console

                    UserResource.getPersonalInfo(token)
                        .then(data => {
                            const userData = data || {};
                            const TODAY = new Date();

                            userData.birthDate = new Date(
                                get(data, 'birthDate')
                            );
                            userData.age = {
                                years: differenceInYears(
                                    TODAY,
                                    userData.birthDate
                                ),
                                months: differenceInMonths(
                                    TODAY,
                                    userData.birthDate
                                ),
                                days: differenceInDays(
                                    TODAY,
                                    userData.birthDate
                                ),
                            };
                            dispatch({
                                type: 'LOGBOOK_USER',
                                payload: userData,
                            });
                        })
                        .catch(err => console.log(err)); // eslint-disable-line no-console

                    setUserToken(token);
                    dispatch({
                        type: 'AUTH',
                        payload: token,
                    });
                })
                .catch(err => console.log('cannot get token', err)); // eslint-disable-line no-console
        }
    };

    const bgAVG = bgs => {
        const bgNumber = bgs.length;

        if (bgNumber === 0) return 'N/A';

        const bgSum = sumBy(bgs, bg => {
            if (get(bg, 'tags.OOR_high')) return 601;
            if (get(bg, 'tags.OOR_low')) return 19;

            return get(bg, 'value');
        });

        return round(bgSum / bgNumber);
    };

    const sortCollectionByDate = (obj, sortedObj) => {
        Object.keys(obj)
            .sort((a, b) => {
                return new Date(b) - new Date(a);
            })
            .forEach(key => {
                sortedObj[key] = obj[key];
            });

        return sortedObj;
    };

    // eslint-disable-next-line no-shadow
    const groupByDate = (allBgs, filterFunc) => {
        // filter BG's by date and then by meal
        const groupedBGs = groupBy([...allBgs], bg => {
            return format(new Date(bg.timestamp), 'yyyy/MM/dd');
        });

        each(groupedBGs, (bgs, dateValue) => {
            const bgsGrouped = groupBy(bgs, bg => {
                return filterFunc(bg);
            });

            bgsGrouped.count = bgs.length;
            bgsGrouped.avg = bgAVG(bgs);

            groupedBGs[dateValue] = bgsGrouped;
        });

        return groupedBGs;
    };

    // eslint-disable-next-line no-shadow
    const filterTagView = allBgs => {
        const filterByCodeFunction = bg => {
            return get(bg, 'tags.eat_code') || -1;
        };
        const mealTagObj = sortCollectionByDate(
            groupByDate(allBgs, filterByCodeFunction),
            {}
        );

        // calculate AVG by Meal
        const groupedByMealArr = groupBy(allBgs, bg => {
            return filterByCodeFunction(bg);
        });

        each(mealCodes, meal => {
            const bgs = groupedByMealArr[meal.value] || [];
            const bgCount = bgs.length;

            bgs.count = bgCount;
            bgs.avg = bgAVG(bgs);
            groupedByMealArr[meal.value] = bgs;
        });

        // all data avg
        const allBgNumber = allBgs.length;
        // recalculate it because the already calculated ones are rounded and may occure errors
        const allBgAvg = bgAVG(allBgs);

        groupedByMealArr[-2] = {count: allBgNumber, avg: allBgAvg};

        const mealTagArr = [];

        for (const item in mealTagObj) {
            if (Object.prototype.hasOwnProperty.call(mealTagObj, item)) {
                mealTagObj[item].bgDate = item;
                mealTagArr.push(mealTagObj[item]);
            }
        }

        dispatch({
            type: 'TAG_VIEW',
            payload: mealTagArr,
        });
        dispatch({
            type: 'TAG_SUMMARY',
            payload: groupedByMealArr,
        });
    };

    // eslint-disable-next-line no-shadow
    const filterTimeView = allBgs => {
        const filterByTimeFunction = bg => {
            return floor(getHours(new Date(get(bg, 'timestamp'))) / 3);
        };
        const bgsTimeSorted = {};

        const mealTime = sortCollectionByDate(
            groupByDate(allBgs, filterByTimeFunction),
            bgsTimeSorted
        );

        // calculate AVG by Time
        const groupedByTimeArr = groupBy(allBgs, bg => {
            return filterByTimeFunction(bg);
        });

        each(timeIntervals, (timeInterval, key) => {
            const bgs = groupedByTimeArr[key] || [];
            const bgCount = bgs.length;

            bgs.count = bgCount;
            bgs.avg = bgAVG(bgs);
            groupedByTimeArr[key] = bgs;
        });

        const summaryAvg =
            allBgs.reduce((total, bg) => total + bg.value, 0) / allBgs.length;

        groupedByTimeArr.summary = {
            avg: Math.round(summaryAvg) ? Math.round(summaryAvg) : 'N/A',
            count: allBgs.length,
        };

        const mealTimeArr = [];

        for (const item in mealTime) {
            if (Object.prototype.hasOwnProperty.call(mealTime, item)) {
                mealTime[item].bgDate = item;
                mealTimeArr.push(mealTime[item]);
            }
        }

        dispatch({
            type: 'TIME_VIEW',
            payload: mealTimeArr,
        });
        dispatch({
            type: 'TIME_SUMMARY',
            payload: groupedByTimeArr,
        });
    };

    const displayCheckBox = (display, section) => {
        if (!controls[display]) return '';
        if (display === 'displayWeight' && userClient === 'HUMANA') return '';

        return (
            <label forhtml={`logbook-checkbox-${section}`}>
                <input
                    id={`logbook-checkbox-${section}`}
                    checked={controls.sections[section]}
                    type="checkbox"
                    value="0"
                    onChange={() => handleCheckChange(section)}
                />
                <span className={css.checkBox}>
                    {display === 'displayWeight'
                        ? t('sectionFilter.weight')
                        : display === 'displayBloodGlucose'
                        ? t('sectionFilter.bg')
                        : t('sectionFilter.htn')}
                </span>
            </label>
        );
    };

    useEffect(() => {
        getUserToken();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getWeightInfo(controls.interval, dispatch, userToken, isGLP1);
        getBgs(userToken);
        getHtnInfo(controls.interval, dispatch, userToken, isGLP1HTN);
    }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        filterTagView(allBgs);
        filterTimeView(allBgs);
    }, [allBgs]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={cn(
                'container-fluid',
                'main-container',
                css['logbook-page']
            )}
            style={{fontSize: '12px'}}
        >
            <div className="column-group">
                <div className="page-content ng-scope">
                    <div className="row">
                        <div className="col-xs-12">
                            <div
                                className={cn('panel', css['section-filters'])}
                            >
                                <div className={css['panel-body']}>
                                    {displayCheckBox('displayWeight', 'weight')}
                                    {displayCheckBox(
                                        'displayBloodGlucose',
                                        'bg'
                                    )}
                                    {displayCheckBox('displayHtn', 'htn')}
                                </div>
                            </div>
                        </div>
                    </div>

                    {!controls.displayWeight || userClient === 'HUMANA' ? (
                        ''
                    ) : (
                        <div className="row">
                            <div className="col-xs-12">
                                <div className={css['section-container']}>
                                    <p className={css['section-title']}>
                                        <i
                                            className="glyphicon glyphicon-play"
                                            style={{
                                                transform: controls.sections
                                                    .weight
                                                    ? 'rotate(90deg)'
                                                    : '',
                                            }}
                                        />
                                        {t('cardHeader.weight')}
                                    </p>
                                    <Weight />
                                </div>
                            </div>
                        </div>
                    )}
                    {controls.displayBloodGlucose ? (
                        <div
                            className={cn(
                                'widgets',
                                css['logbook-page'],
                                css.pdfDisplayBlock
                            )}
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <div className={css['section-container']}>
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <p
                                                    className={
                                                        css['section-title']
                                                    }
                                                >
                                                    <i
                                                        className="glyphicon glyphicon-play"
                                                        style={{
                                                            transform: controls
                                                                .sections.bg
                                                                ? 'rotate(90deg)'
                                                                : '',
                                                        }}
                                                    />
                                                    {t('cardHeader.bg')}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: controls.sections.bg
                                                    ? ''
                                                    : 'none',
                                            }}
                                        >
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <div
                                                        className={
                                                            css[
                                                                'section-content'
                                                            ]
                                                        }
                                                    >
                                                        <BGHeader />
                                                        <SummaryView
                                                            bgStatistics={
                                                                bgStatistics
                                                            }
                                                        />
                                                        <ListView />
                                                        <TagView />
                                                        <TimeView />

                                                        <div
                                                            className={cn(
                                                                'col-md-12',
                                                                css[
                                                                    'legend-content-pdf'
                                                                ]
                                                            )}
                                                        >
                                                            Legend:
                                                            <span>
                                                                {' '}
                                                                <span
                                                                    className={cn(
                                                                        css[
                                                                            'in-range-legend'
                                                                        ],
                                                                        css[
                                                                            'legend-bg'
                                                                        ]
                                                                    )}
                                                                >
                                                                    &nbsp;
                                                                </span>{' '}
                                                                BG In Range{' '}
                                                            </span>
                                                            <span>
                                                                {' '}
                                                                <span
                                                                    className={cn(
                                                                        css[
                                                                            'below-range-legend'
                                                                        ],
                                                                        css[
                                                                            'legend-bg'
                                                                        ]
                                                                    )}
                                                                >
                                                                    &nbsp;
                                                                </span>{' '}
                                                                BG Below Range{' '}
                                                            </span>
                                                            <span>
                                                                {' '}
                                                                <span
                                                                    className={cn(
                                                                        css[
                                                                            'above-range-legend'
                                                                        ],
                                                                        css[
                                                                            'legend-bg'
                                                                        ]
                                                                    )}
                                                                >
                                                                    &nbsp;
                                                                </span>{' '}
                                                                BG Above Range{' '}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {!controls.displayHtn ? (
                        ''
                    ) : (
                        <div className="row">
                            <div className="col-xs-12">
                                <div className={css['section-container']}>
                                    <p className={css['section-title']}>
                                        <i
                                            className="glyphicon glyphicon-play"
                                            style={{
                                                transform: controls.sections.htn
                                                    ? 'rotate(90deg)'
                                                    : '',
                                            }}
                                        />
                                        {t('cardHeader.htn')}
                                    </p>
                                    <Htn />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-xs-12">
                            <div className={css['section-container']}>
                                <p className={css['section-title']}>
                                    {t('cardHeader.lab')}
                                </p>
                                <LabResults authToken={userToken} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logbook;
