import Arg from '@livongo/arg';
import {HtnResource} from '../res_htn';

export const getHtnInfo = async (interval, dispatch, userToken, isGLP1HTN) => {
    const conditions = (Arg('conditions') || '').split(';');
    const htnConditions = [
        'Hypertension',
        'Weight_Management',
        'Chronic_Kidney_Disease',
        'Heart_Failure',
    ];
    const displayHtn =
        conditions.filter(condition => htnConditions.includes(condition))
            .length > 0 || isGLP1HTN;

    // Display HTN component if any of these conditions are listed in the query
    if (displayHtn) {
        dispatch({
            type: 'DISPLAY_HTN',
            payload: true,
        });
        dispatch({
            type: 'SET_SECTION',
            payload: {
                selected: 'htn',
                value: true,
            },
        });

        if (userToken) {
            await HtnResource.getHtnReadings(interval, userToken)
                .then(data => {
                    const tempBpStats = {};

                    tempBpStats.overall = data.overallStats;
                    tempBpStats.last30 =
                        data.monthlyStats.length > 0
                            ? data.monthlyStats[0]
                            : [];
                    tempBpStats.last90 = data.ninetyDayStats;
                    tempBpStats.allReading = data.readings;
                    dispatch({
                        type: 'HTN_DATA',
                        payload: tempBpStats,
                    });
                })
                .catch(err => {
                    console.log(err); // eslint-disable-line no-console
                });
        }
    } else {
        dispatch({
            type: 'DISPLAY_HTN',
            payload: false,
        });
    }
};
